%margin-centering {
  margin-left: auto;
  margin-right: auto;
}

%flex-centering {
  display: flex;
  align-items: center;
  justify-content: center;
}

%font-size-extra-large {
  font-size: $text-font-size-extra-large;
}

%font-size-large {
  font-size: $text-font-size-large;
}

%font-size-medium {
  font-size: $text-font-size-medium;
}

%font-size-small {
  font-size: $text-font-size-small;
}

%font-size-extra-small {
  font-size: $text-font-size-extra-small;
}

%font-weight-light {
  font-weight: 300;
}

%font-weight-medium {
  font-weight: 500;
}

%font-weight-bold {
  font-weight: 700;
}

%font-weight-black {
  font-weight: 900;
}