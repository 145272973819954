.u-hidden-phone-small {
  @include breakpoint(phone-small) {
    display: none !important;
  }
}

.u-hidden-phone {
  @include breakpoint(phone) {
    display: none !important;
  }
}

.u-hidden-tablet {
  @include breakpoint(tablet) {
    display: none !important;
  }
}

.u-hidden-desktop-small {
  @include breakpoint(desktop-small) {
    display: none !important;
  }
}

.u-visible-phone-small {
  display: none !important;
  @include breakpoint(phone-small) {
    display: block !important;
  }
}

.u-visible-phone {
  display: none !important;
  @include breakpoint(phone) {
    display: block !important;
  }
}

.u-visible-tablet {
  display: none !important;
  @include breakpoint(tablet) {
    display: block !important;
  }
}

.u-abs {
  position: absolute;
  z-index: -1;
}