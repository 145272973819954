.hr {
  width: 100%;
  opacity: 0.3;
  height: 0.3rem;
  border-color: #4d4d4d;

  &--abs {
    position: absolute;
  }


  .hero .bg-images & {
    width: 89.5rem;
  }

  .steps & {
    left: 0;
    top: 12.6rem;
    width: 93.3rem;
  }

  .why .bg-images & {
    width: 94.6rem;

    &:nth-of-type(1) {
      top: 28rem;
      left: 0;
    }

    &:nth-of-type(2) {
      bottom: 19.5rem;
      right: 0;
    }
  }

  .what .bg-images &,
  .sliders .bg-images &,
  .next-game .bg-images & {
    width: 35.9rem;
    background-color: $color-dark-3;
  }

  .sliders .bg-images & {
    left: 0;
    bottom: 4.6rem;
  }


  .what .bg-images & {
      left: -35.9rem;
      top: 16rem;
  }

  .next-game .bg-images & {
    &:nth-of-type(1) {
      right: -35.9rem;
      top: -14.3rem;
    }

    &:nth-of-type(2) {
      top: 0;
      right: 14.5rem;
    }

    &:nth-of-type(3) {
      left: -15.5rem;
      top: 23rem;
    }
  }
}


