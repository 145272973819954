.btn {
  transition:
    background-color 300ms ease,
    transform 300ms ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border: none;
  transform: scale(1);
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden;
  color: $color-white;

  &--reg {
    width: 28.2rem;
    height: 7.3rem;
    @extend %font-size-medium;
    @extend %font-weight-light;
  }

  &--submit {
    display: block;
    margin: 3.8rem auto 0;
    width: 23.4rem;
    height: 4.8rem;
    font-size: 1.6rem;
    @extend %font-weight-medium;
  }
  
  &--reg,
  &--submit {
    background-color: #ad0000;
  }

  &--reg:hover,
  &--reg:focus {
    background-color: #ff0000;
    transform: scale(.95) perspective(1px);
  }

  &--submit:hover,
  &--submit:focus {
    background-color: $color-black;
  }

  &--arrow {
    background-color: transparent;
    outline: none;
    
    .slider--photo ~ .slider__control & {
      width: 6rem;
      height: 3.4rem;
    }

    .slider--testimonials ~ .slider__control & {
      width: 4.2rem;
      height: 2.4rem;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    .arrow {
      fill: $color-white;
      transition: fill 300ms ease-out;
    }

    &:hover,
    &:focus,
    &:active {
      .arrow {
        fill: $color-red;
      }
    }
  }

  &--close-popup {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 3rem;
    height: 3rem;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin: 0;
    color: $color-black;

    &:hover,
    &:target,
    &:active {
      color: $color-red;
    }

    span {
      position: absolute;
      height: 2.1rem;
      width: 1px;
      background-color: currentColor;
      transition: background-color 300ms ease;

      &:nth-child(1) {
        transform: rotate(-135deg);
      }
      &:nth-child(2) {
        transform: rotate(135deg);
      }
    }
  }
}