.link {
  text-decoration: none;
  transition: color 300ms ease;
  
  &--large {
    font-size: $text-font-size-large;
  }

  &--dark {
    color: $color-gray;
  }

  &--light {
    color: $color-white;
  }

  &--dynamic {
    color: currentColor;
  }

  &:hover,
  &:focus,
  &:active {
    color: $color-red;
  }
}