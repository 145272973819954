.h1, .h2, .h3 {
  font-weight: 700;
}

.h1, .h2 {
  font-size: $h1-font-size;
  text-transform: uppercase;
  font-family: $font-roboto-slab;
  line-height: 1;
}

.h3 {
  font-size: $h3-font-size;

  &--why {
    color: #878787;
  }

  &--popup {
    font-family: $font-roboto-slab;
    color: $color-red;
    line-height: 1;
    text-transform: uppercase;

  }
}
