.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 9999 !important;

  &--thank {
    display: block;
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .4);
    text-align: center;
    overflow: auto;
    white-space: nowrap;
    text-align: center;
    font-size: 0;

    &:before {
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      content: '';
    }
  }
}

.modal {
  position: relative;
  display: inline-block;
  padding: 6rem 5.1rem 6rem 4.7rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: $color-white;
  white-space: normal;
  vertical-align: middle;
  overflow: hidden;
  
  &__heading {
    color: $color-red;
    text-transform: uppercase;
    
    &--reg {
      margin-bottom: 1.3rem;
      text-align: center;
    }

    &--thank {
      margin-bottom: 2.4rem;
      text-align: left;
    }
  }

  &--thank {
    padding: 6.1rem 4.9rem 5rem 3.9rem;
    // width: 105rem;
    // min-height: auto;
  }

  .subheading {
    text-align: left;
    @extend %font-size-extra-small;

    &--gray {
      color: #6a6a6a;;
    }
    &--red {
      color: $color-red;;
    }
    // line-height: 1.2;
    // @extend %font-weight-medium;
  }

  @include breakpoint(phone) {
    &--thank {
      width: 95%;
    }
  }
}

.form {
  margin-top: 3.5rem;
  margin-bottom: 1.4rem;
  color: $color-black;
  text-align: left;
  
  &__field,
  &__label {
    @extend %font-size-small;
  }

  &__label {
    display: block;
    margin-bottom: 1.4rem;
    line-height: 1;
    @extend %font-weight-medium;
  }

  &__field {
    display: block;
    max-width: 100%;
    width: 38.8rem;
    padding-left: 1.1rem;
    line-height: 2.9rem;
    border: 1px solid #dcdcdc;
    height: 4.2rem;
    transition: border 250ms ease;
    @extend %font-weight-light;
    
    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    // &--select:nth-child(2) {
    //   margin-bottom: 0;
    // }

    &::placeholder {
      color: $color-black;
    }
  }
}

.error {
  margin-bottom: 2rem;
  display: none;
  color: red;
  font-size: 2rem;
}
