.container {
  position: relative;
  width: 100%;
  @extend %margin-centering;
  
  &--medium {
    max-width: $container-width-medium;
  }

  &--large {
    max-width: $container-width-large;
  }
}

.row {
  display: flex;
  margin-left: -#{$horizontal-gap / 2};
  margin-right: -#{$horizontal-gap / 2};
  flex-wrap: wrap;
}

.col-1-of-2 {
  flex: 1 1 calc(50% - #{$horizontal-gap});
  position: relative;
  margin-left: #{$horizontal-gap / 2};
  margin-right: #{$horizontal-gap / 2};
}

.col-1-of-3 {
  flex: 1 1 33.3333%;
  position: relative;
}

@include breakpoint(tablet) {
  .container {
    padding-left: $container-padding-mobile;
    padding-right: $container-padding-mobile;
  }

  .col-1-of-2 {
    flex: 1 1 100%;
  }
  
  .col-1-of-3 {
    flex: 1 1 50%;
  }
}

@include breakpoint(phone) {
  .col-1-of-3 {
    flex: 1 1 100%;
  }

  .container {
    max-width: 100%;
  }
}