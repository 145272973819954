
/** 
  Hero
**/
.hero {
  padding-top: 8rem;

  &__description {
    margin: 11.6rem 0;
    line-height: 1.1;
    @extend %font-size-medium;
    @extend %font-weight-light;
  }

  .h1,
  .btn,
  &__description { 
    position: relative;
    z-index: 5;
  }

  .btn {
    margin-left: 51rem;
  }

  @include breakpoint(phone) {
    &__description {
      margin: 8.5rem 0;
    }

    .btn {
      margin-left: 0;
    }
  }
}


/** 
  Steps
**/
.steps {
  padding-top: 14.5rem;

  &:not(.bg-images) {
    z-index: 2;
  }

  &__list {
    margin-top: 14rem;
    margin-bottom: 7rem;
  }

  &__item {
    @extend %font-size-medium;
    @extend %font-weight-light;
    line-height: 1.25;

    &:nth-child(-n + 2) {
      margin-bottom: 8rem;
    }
  }

  &__number {
    display: inline-block;
    width: 3.1rem;
    height: 3.1rem;
    text-align: center;
    background-color: $color-red;
  }

  .btn {
    margin-left: calc(50% + #{$horizontal-gap / 2});
  }

  @include breakpoint(tablet) {
    padding-top: 9rem;

    &__list {
      margin-top: 8rem;
      margin-bottom: 7rem;
    }

    &__item {
      &:nth-child(-n + 3) {
        margin-bottom: 4rem;
      }
    }

    .btn {
      margin-left: 0;
    }
  }
}



.why {
  padding-top: 20rem;
  padding-bottom: 36rem;
  z-index: 3;
  
  &__list {
    margin-top: 15rem;
  }

  &__item {
    margin-left: #{$horizontal-gap / 2};
    margin-right: #{$horizontal-gap / 2};
    @extend %font-weight-light;

    &:nth-child(-n + 4) {
      margin-bottom: 6.3rem;
    }

    &:nth-child(odd) {
      width: calc(44% - #{$horizontal-gap / 2});
    }
    &:nth-child(even) {
      max-width: calc(56% - #{$horizontal-gap / 2});
    }

    &:last-child {
      display: flex;
      align-items: flex-end;
    }
  }
  
  .h3 {
    margin-bottom: 1.5rem;
    color: $color-gray;
  }
  
  &__text {
    line-height: 2.4rem;
    @extend %font-size-small;
  }

  @include breakpoint(phone) {
    padding-top: 9rem;
    padding-bottom: 9rem;

    &__list {
      margin-top: 8rem;
    }
 
    &__item {
      &:nth-child(n) {
        width: 100%;
        max-width: 100%;
      }

      &:nth-child(n) {
        margin-bottom: 6.3rem;
      }
    }

    &__text {
      font-size: 2.4rem;
    }
  }
  
}


.sliders {
  padding-bottom: 26.4rem;
  z-index: 2;

  .container {
    display: flex;
  }

  .h2 {
    margin-left: 12.5rem;;
  }

  &__left {
    width: 100%;
    max-width: calc(50% - #{$horizontal-gap});
    position: relative;
  }

  &__right {
    width: 100%;
    max-width: calc(50% + #{$horizontal-gap});
  }
  // .slider--photo {
  //   & ~ .slider__control {
  //     bottom: -3rem;
  //     right: 5rem;
  //     width: 16rem;
  //     height: 6rem;
  //     padding-right: 0;

  //     .btn--arrow {
  //       height: 100%;
  //       width: 100%;
  //     }

  //     .btn--arrow svg {
  //       height: 4rem;
  //     }
  //   }
  // }

  // .slider--testimonials {
  //   .btn--arrow {
  //     width: 4.2rem;
  //     height: 2.4rem;
  //   }
  // }




  @include breakpoint(tablet) {
    padding-bottom: 13rem;

    &__left,
    &__right {
      width: 100%;
      max-width: 100%;
    }

    .h2 {
      margin-left: 0;
      margin-bottom: 8rem;
    }

    .slider--photo {
      & ~ .slider__control {
        bottom: -3rem;
        right: 5rem;
        width: 16rem;
        height: 6rem;
        padding-right: 0;

        .btn--arrow {
          height: 100%;
          width: 100%;
        }
        .btn--arrow svg {
          height: 4rem;
        }
      }
    } 
  
    .container {
      flex-direction: column;
    }
  }

}


.what {
  padding-bottom: 20.6rem;

  &__list {
      margin-top: 10.8rem;
      margin-bottom: 11.4rem;
  }

  &__item {
    position: relative;
    @extend %font-weight-light;
    
    &:not(:last-child) {
      margin-bottom: 5rem;
    }
  }

  &__icon {
    position: absolute;
    
    &.phone-icon {
      left: -5rem;
      width: 1.8rem;
    }
    
    &.people-icon {
      left: -6rem;
      width: 3.5rem;
    }
  }

  &__text {
    line-height: 1.25;
    @extend %font-size-medium;
  }

  &__footprint {
    position: relative;
    font-style: italic;
    @extend %font-size-small;

    span {
      color: $color-red;
    }

    &::before {
      content: "*";
      position: absolute;
      top: 0;
      left: -4.9rem;
      font-size: 3rem;
      line-height: 0.95;
      font-style: normal;
      color: $color-red;
      @extend %font-weight-bold;
    }
  }

  @include breakpoint(tablet) {
    padding-bottom: 9rem;

    &__list {
      margin-top: 8rem;
      margin-bottom: 8rem;
      margin-left: 6rem;  
    }
  }
}

.next-game {
  padding-bottom: 17rem;
  
  @include breakpoint(phone) {
    padding-bottom: 9rem;
  }
}

.timer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: -10.5rem;
  margin-top: 5rem;

  &__box {
    display: flex;
    align-items: flex-end;
  }

  &__cell {
    // height: 13rem;
    // width: 12.5rem;
    margin-right: 7.7rem;

    &::last-child {
      margin-right: 5.2rem;
    }
  }

  &__label {
    line-height: 1;
    @extend %font-size-large;
    @extend %font-weight-light;

    &--dark {
      color: #717171;
      margin-right: 6.2rem;
    }

    &--light {

    }
  }

  &__number {
    width: 11.8rem;
    text-align: left;
    font-size: 10rem;
    text-transform: uppercase;
    @extend %font-weight-black;
  }

  @include breakpoint(tablet) {
    margin-right:0;
  }

  @include breakpoint(phone) {
    flex-direction: column;
    align-items: flex-start;

    &__box {
      margin-bottom: 8rem;
    }

    &__cell {
      &:not(:last-child) {
        margin-right: 3rem;
      }
    }
    
  }
}