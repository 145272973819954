.bullet {
  top: 1.7rem;
  left: -32.5rem;
  width: 29rem;
  height: 4.4rem;
  opacity: 0.24;
}

.evidence {
  top: 26.5rem;
  left: -57rem;
  width: 70.8rem;
  height: 51.7rem;
  z-index: -1;
}

.piece-of-photo {
  top: 23rem;
  left: 0;
  width: 11.9rem;
  height: 51.7rem;
}

.lanterns {
  top: 42rem;
  right: -37.5rem;
  width: 194.7rem;
  height: 175.7rem;
  z-index: -2;
}

.fingerprint {
  left: -37.3rem;
  top: -10rem;
  width: 30rem;
  height: 36.8rem;
}

.detective {
  z-index: -1;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  font-size: 0;
  line-height: 0;

  &--1 {
    top: -29rem;
    right: -54rem;
    width: 108rem;
    height: 194.9rem;
  }

  &--2 {
    width: 77.2rem;
    height: 160rem;
    top: -10rem;
    right: -25rem;
  }

  img {
    width: 100%;
    object-fit: cover;
    display: block;
    float: left;
  }

  &__overlay {
    position: absolute;
    z-index: 10;
    right: -36rem;
    width: 75rem;
    height: 112rem;
  }
  
  &--1 + &__overlay {
    top: -28rem;
  }

  &--2 + &__overlay {
    top: -12rem;
  }

  // Hero Detective Image 
  &--1 &__line {
    &--2 {
      transform: translate3d(10%, 0, 0);
      transition: transform 600ms;
    }

    &--4 {
      transform: translate3d(-10%, 0, 0);
      transition: transform 500ms  150ms;
    }

    &--6 {
      transform: translate3d(15%, 0, 0);
      transition: transform 800ms  100ms;
    }

    &--8 {
      transform: translate3d(-20%, 0, 0);
      transition: transform 550ms 250ms;
    }

    &--10 {
      transform: translate3d(10%, 0, 0);
      transition: transform 900ms;
    }

    &--12 {
      transform: translate3d(-20%, 0, 0);
      transition: transform 600ms 200ms;
    }

    &--14 {
      transform: translate3d(-20%, 0, 0);
      transition: transform 600ms 200ms;
    }
  }

  // Timer Detective Image 
  &--2 &__line {
    &--2 {
      transform: translate3d(10%, 0, 0);
      transition: transform 600ms;
    }

    &--4 {
      transform: translate3d(-10%, 0, 0);
      transition: transform 500ms  150ms;
    }

    &--6 {
      transform: translate3d(15%, 0, 0);
      transition: transform 800ms  100ms;
    }

    &--8 {
      transform: translate3d(-20%, 0, 0);
      transition: transform 550ms 250ms;
    }

    &--10 {
      transform: translate3d(10%, 0, 0);
      transition: transform 900ms;
    }

    &--11 {
      transform: translate3d(10%, 0, 0);
      transition: transform 900ms;
    }
  }
}

.rect {

  &__hero {
    &-1,
    &-2,
    &-3,
    &-4,
    &-5,
    &-6 {
      height: 2.8rem;
    }

    &-1,
    &-2,
    &-3,
    &-4,
    &-5 {
      width: 10.2rem;
    }

    &-1 {
      right: -18rem;
      top: -2rem;
      transition: width 600ms ease 400ms;
    }

    &-2 {
      right: -12rem;
      top: 38rem;
      transition: width 800ms ease 950ms;
    }

    &-3 {
      opacity: .5;
      left: -5rem;
      top: 42.5em;
      z-index: 0;
    }

    &-4 {
      left: -2.5rem;
      top: 43rem;
    }

    &-5 {
      right: 20.5rem;
      top: 70.5rem;
      transition: width 900ms ease 700ms;
    }

    &-6 {
      top: 90.5rem;
      right: -53.7rem;
      width: 29.4rem;
      transition: width 700ms ease 500ms;
    }
  }

  &__what {
    &-1,
    &-4,
    &-5 {
      width: 14.7rem;
    }

    &-1,
    &-3,
    &-4,
    &-5 {
      height: 1.8rem;
    }

    &-1 {
      right: -4.5rem;
      top: 0;
      transition: width 700ms ease 500ms;
    }

    &-2 {
      right: -19rem;
      top: 46.5rem;
      width: 32.6rem;
      height: 3.6rem;
      transition: width 900ms ease 800ms;
    }

    &-3 {
      right: 33.5rem;
      top: 65rem;
      width: 5.2rem;
      transition: width 500ms ease 700ms;
    }

    &-4 {
      right: -12rem;
      top: 68.5rem;
      transition: width 800ms ease 800ms;
    }

    &-5 {
      top: 88.7rem;
      right: 36.5rem;
      transition: width 600ms ease 700ms;
    }
  }


  &--dark {
    background-color: $color-dark-1;
  }

  &--red {
    background-color: $color-red;
  }

  &--light {
    background-color: #4d4d4d;
  }

}

@include breakpoint(tablet) {
  .detective {
    &__overlay {
      display: none;
    } 
  }
}