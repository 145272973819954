// Colors
$color-black: #000;
$color-white: #fff;
$color-gray: #878787;
$color-red: #ad0000;
$color-dark-1: #1f1f1f;
$color-dark-2: #2d2d2d;
$color-dark-3: #4d4d4d;

$text-color: $color-white;

$text-font-size-extra-small: 1.4rem;
$text-font-size-small: 1.8rem;
$text-font-size-medium: 2.4rem;
$text-font-size-large: 3rem;
$text-font-size-extra-large: 3.6rem;

$h1-font-size: 6rem;
$h2-font-size: 6rem;
$h3-font-size: $text-font-size-large;

$font-roboto: "Roboto", Arial, Helvetica, sans-serif;
$font-roboto-slab: "Roboto Slab", Arial, Helvetica, sans-serif;

// Elements sizes
$container-width-medium: 120rem;
$container-width-large: 192rem;

$horizontal-gap: 8rem;

$container-padding-desktop: 2.5rem;
$container-padding-mobile: 4.5rem;