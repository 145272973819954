@mixin breakpoint($size) {
  @if $size==phone-small {
    @media only screen and (max-width: 450px) {
      @content;
    }   
  }
  @if $size==phone {
    @media only screen and (max-width: 768px) {
      @content;
    }   
  }
  @if $size==tablet {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }
  @if $size==desktop-small {
    @media only screen and (max-width: 1300px){
      @content;
    }
  }
  // @if $size==desktop-medium {
  //   @media only screen and (max-width: 1300px){
  //     @content;
  //   }
  // }
  @if $size==desktop-large {
    @media only screen and (min-width: 1920px) {
      @content;
    };
  }
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    @content;
  }
}