.slider {
  &--photo {
    img {
      width: 100%;
    }

    & + .slider__control {
      position: absolute;
      bottom: -3.65rem;
      right: $horizontal-gap;
      padding-right: 4.3rem;
      width: 28.2rem;
      height: 7.3rem;
      background-color: $color-dark-2;
      text-align: right;
      
      .btn {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

  }

  &--testimonials {
    margin-top: 13.6rem;
    max-width: 105rem;
    margin-left: -#{$horizontal-gap + 2};

    & + .slider__control {
      margin-top: 6rem;
      text-align: right;
      max-width: 92rem;

      .btn {
        margin-right: $horizontal-gap;
      }
    }

    .slide {

    }
  }
}

.testimonial {
  display: flex !important;
  margin-left: 2rem;
  margin-right: 2rem;

  &__photo-box {
    margin-right: 2.4rem;  
  }
  
  &__photo {
    width: 18.3rem;
    height: 18.3rem;

    img {
      width: 100%;
    }
  }

  &__text {

  }

  &__name {
    color: $color-red;
    margin-bottom: 3.3rem;

    @extend %font-size-extra-large;
    @extend %font-weight-bold;
  }

  &__body {
    padding-left: $horizontal-gap;
    line-height: 1.1;
    @extend %font-size-medium;
    @extend %font-weight-light;
  }
}

@include breakpoint(tablet) {
  .slider--photo {
    margin-left: -#{$container-padding-mobile};
    margin-right: -#{$container-padding-mobile};
  }
  
  .slider--testimonials {
    margin-left: 0;

    & + .slider__control {
      margin-top: 4rem;
    }
  }

  .testimonial {
    flex-direction: column;

    &__photo-box {
      display: flex;
      align-items: flex-end;
      margin-right: 0;
      margin-bottom: 3rem;
    }

    &__name {
      margin-bottom: 0;
      margin-left: 3rem;
    }

    &__body {
      padding-left: 0;
    }
  }
}