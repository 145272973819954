@charset "UTF-8";
/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
  display: none !important;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.testimonial__name {
  font-size: 3.6rem;
}

.timer__label {
  font-size: 3rem;
}

.btn--reg, .testimonial__body, .hero__description, .steps__item, .what__text {
  font-size: 2.4rem;
}

.form__field, .form__label, .why__text, .what__footprint {
  font-size: 1.8rem;
}

.modal .subheading {
  font-size: 1.4rem;
}

.btn--reg, .form__field, .testimonial__body, .header .link, .hero__description, .steps__item, .why__item, .what__item, .timer__label {
  font-weight: 300;
}

.btn--submit, .form__label {
  font-weight: 500;
}

.testimonial__name, .what__footprint::before {
  font-weight: 700;
}

.timer__number {
  font-weight: 900;
}

/* roboto-300 - cyrillic_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/roboto-v18-cyrillic_latin-300.woff2") format("woff2"), url("../fonts/roboto-v18-cyrillic_latin-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300italic - cyrillic_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("../fonts/roboto-v18-cyrillic_latin-300italic.woff2") format("woff2"), url("../fonts/roboto-v18-cyrillic_latin-300italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - cyrillic_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/roboto-v18-cyrillic_latin-500.woff2") format("woff2"), url("../fonts/roboto-v18-cyrillic_latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700italic - cyrillic_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("../fonts/roboto-v18-cyrillic_latin-700italic.woff2") format("woff2"), url("../fonts/roboto-v18-cyrillic_latin-700italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-900 - cyrillic_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"), url("../fonts/roboto-v18-cyrillic_latin-900.woff2") format("woff2"), url("../fonts/roboto-v18-cyrillic_latin-900.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-slab-700 - cyrillic_latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Slab Bold"), local("RobotoSlab-Bold"), url("../fonts/roboto-slab-v7-cyrillic_latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v7-cyrillic_latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/*********** BASE ***********/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline-color: #ad0000;
}

:root {
  font-size: 43.75%;
}

@media only screen and (min-width: 1920px) {
  :root {
    font-size: 62.5%;
  }
}

@media only screen and (max-width: 1300px) {
  :root {
    font-size: 41%;
  }
}

@media only screen and (max-width: 1024px) {
  :root {
    font-size: 50%;
  }
}

@media only screen and (max-width: 768px) {
  :root {
    font-size: 38%;
  }
}

@media only screen and (max-width: 450px) {
  :root {
    font-size: 1.6vw;
  }
}

::selection {
  background-color: #ad0000;
  color: #fff;
}

html {
  overflow-x: hidden;
}

body {
  min-height: 100%;
  background-color: #000;
  box-sizing: border-box;
  color: #fff;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

section {
  position: relative;
}

picture {
  display: block;
}

picture img {
  display: block;
  width: 100%;
}

ul {
  list-style: none;
}

address {
  font-style: normal;
}

select, input, button {
  font-family: inherit;
}

option {
  font-family: inherit;
  font-weight: inherit;
}

select {
  background-color: transparent;
  position: relative;
}

option:hover {
  background-color: #ad0000;
}

/**********************/
.h1, .h2, .h3 {
  font-weight: 700;
}

.h1, .h2 {
  font-size: 6rem;
  text-transform: uppercase;
  font-family: "Roboto Slab", Arial, Helvetica, sans-serif;
  line-height: 1;
}

.h3 {
  font-size: 3rem;
}

.h3--why {
  color: #878787;
}

.h3--popup {
  font-family: "Roboto Slab", Arial, Helvetica, sans-serif;
  color: #ad0000;
  line-height: 1;
  text-transform: uppercase;
}

@media only screen and (max-width: 450px) {
  .u-hidden-phone-small {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .u-hidden-phone {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  .u-hidden-tablet {
    display: none !important;
  }
}

@media only screen and (max-width: 1300px) {
  .u-hidden-desktop-small {
    display: none !important;
  }
}

.u-visible-phone-small {
  display: none !important;
}

@media only screen and (max-width: 450px) {
  .u-visible-phone-small {
    display: block !important;
  }
}

.u-visible-phone {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .u-visible-phone {
    display: block !important;
  }
}

.u-visible-tablet {
  display: none !important;
}

@media only screen and (max-width: 1024px) {
  .u-visible-tablet {
    display: block !important;
  }
}

.u-abs {
  position: absolute;
  z-index: -1;
}

.btn {
  transition: background-color 300ms ease, transform 300ms ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border: none;
  transform: scale(1);
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden;
  color: #fff;
}

.btn--reg {
  width: 28.2rem;
  height: 7.3rem;
}

.btn--submit {
  display: block;
  margin: 3.8rem auto 0;
  width: 23.4rem;
  height: 4.8rem;
  font-size: 1.6rem;
}

.btn--reg, .btn--submit {
  background-color: #ad0000;
}

.btn--reg:hover, .btn--reg:focus {
  background-color: #ff0000;
  transform: scale(0.95) perspective(1px);
}

.btn--submit:hover, .btn--submit:focus {
  background-color: #000;
}

.btn--arrow {
  background-color: transparent;
  outline: none;
}

.slider--photo ~ .slider__control .btn--arrow {
  width: 6rem;
  height: 3.4rem;
}

.slider--testimonials ~ .slider__control .btn--arrow {
  width: 4.2rem;
  height: 2.4rem;
}

.btn--arrow svg {
  display: block;
  width: 100%;
  height: 100%;
}

.btn--arrow .arrow {
  fill: #fff;
  transition: fill 300ms ease-out;
}

.btn--arrow:hover .arrow, .btn--arrow:focus .arrow, .btn--arrow:active .arrow {
  fill: #ad0000;
}

.btn--close-popup {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 3rem;
  height: 3rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0;
  color: #000;
}

.btn--close-popup:hover, .btn--close-popup:target, .btn--close-popup:active {
  color: #ad0000;
}

.btn--close-popup span {
  position: absolute;
  height: 2.1rem;
  width: 1px;
  background-color: currentColor;
  transition: background-color 300ms ease;
}

.btn--close-popup span:nth-child(1) {
  transform: rotate(-135deg);
}

.btn--close-popup span:nth-child(2) {
  transform: rotate(135deg);
}

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 9999 !important;
}

.popup--thank {
  display: block;
}

.popup__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  overflow: auto;
  white-space: nowrap;
  text-align: center;
  font-size: 0;
}

.popup__overlay:before {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
}

.modal {
  position: relative;
  display: inline-block;
  padding: 6rem 5.1rem 6rem 4.7rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: #fff;
  white-space: normal;
  vertical-align: middle;
  overflow: hidden;
}

.modal__heading {
  color: #ad0000;
  text-transform: uppercase;
}

.modal__heading--reg {
  margin-bottom: 1.3rem;
  text-align: center;
}

.modal__heading--thank {
  margin-bottom: 2.4rem;
  text-align: left;
}

.modal--thank {
  padding: 6.1rem 4.9rem 5rem 3.9rem;
}

.modal .subheading {
  text-align: left;
}

.modal .subheading--gray {
  color: #6a6a6a;
}

.modal .subheading--red {
  color: #ad0000;
}

@media only screen and (max-width: 768px) {
  .modal--thank {
    width: 95%;
  }
}

.form {
  margin-top: 3.5rem;
  margin-bottom: 1.4rem;
  color: #000;
  text-align: left;
}

.form__label {
  display: block;
  margin-bottom: 1.4rem;
  line-height: 1;
}

.form__field {
  display: block;
  max-width: 100%;
  width: 38.8rem;
  padding-left: 1.1rem;
  line-height: 2.9rem;
  border: 1px solid #dcdcdc;
  height: 4.2rem;
  transition: border 250ms ease;
}

.form__field:not(:last-child) {
  margin-bottom: 3rem;
}

.form__field::placeholder {
  color: #000;
}

.error {
  margin-bottom: 2rem;
  display: none;
  color: red;
  font-size: 2rem;
}

.bullet {
  top: 1.7rem;
  left: -32.5rem;
  width: 29rem;
  height: 4.4rem;
  opacity: 0.24;
}

.evidence {
  top: 26.5rem;
  left: -57rem;
  width: 70.8rem;
  height: 51.7rem;
  z-index: -1;
}

.piece-of-photo {
  top: 23rem;
  left: 0;
  width: 11.9rem;
  height: 51.7rem;
}

.lanterns {
  top: 42rem;
  right: -37.5rem;
  width: 194.7rem;
  height: 175.7rem;
  z-index: -2;
}

.fingerprint {
  left: -37.3rem;
  top: -10rem;
  width: 30rem;
  height: 36.8rem;
}

.detective {
  z-index: -1;
  font-size: 0;
  line-height: 0;
}

.detective--1 {
  top: -29rem;
  right: -54rem;
  width: 108rem;
  height: 194.9rem;
}

.detective--2 {
  width: 77.2rem;
  height: 160rem;
  top: -10rem;
  right: -25rem;
}

.detective img {
  width: 100%;
  object-fit: cover;
  display: block;
  float: left;
}

.detective__overlay {
  position: absolute;
  z-index: 10;
  right: -36rem;
  width: 75rem;
  height: 112rem;
}

.detective--1 + .detective__overlay {
  top: -28rem;
}

.detective--2 + .detective__overlay {
  top: -12rem;
}

.detective--1 .detective__line--2 {
  transform: translate3d(10%, 0, 0);
  transition: transform 600ms;
}

.detective--1 .detective__line--4 {
  transform: translate3d(-10%, 0, 0);
  transition: transform 500ms  150ms;
}

.detective--1 .detective__line--6 {
  transform: translate3d(15%, 0, 0);
  transition: transform 800ms  100ms;
}

.detective--1 .detective__line--8 {
  transform: translate3d(-20%, 0, 0);
  transition: transform 550ms 250ms;
}

.detective--1 .detective__line--10 {
  transform: translate3d(10%, 0, 0);
  transition: transform 900ms;
}

.detective--1 .detective__line--12 {
  transform: translate3d(-20%, 0, 0);
  transition: transform 600ms 200ms;
}

.detective--1 .detective__line--14 {
  transform: translate3d(-20%, 0, 0);
  transition: transform 600ms 200ms;
}

.detective--2 .detective__line--2 {
  transform: translate3d(10%, 0, 0);
  transition: transform 600ms;
}

.detective--2 .detective__line--4 {
  transform: translate3d(-10%, 0, 0);
  transition: transform 500ms  150ms;
}

.detective--2 .detective__line--6 {
  transform: translate3d(15%, 0, 0);
  transition: transform 800ms  100ms;
}

.detective--2 .detective__line--8 {
  transform: translate3d(-20%, 0, 0);
  transition: transform 550ms 250ms;
}

.detective--2 .detective__line--10 {
  transform: translate3d(10%, 0, 0);
  transition: transform 900ms;
}

.detective--2 .detective__line--11 {
  transform: translate3d(10%, 0, 0);
  transition: transform 900ms;
}

.rect__hero-1, .rect__hero-2, .rect__hero-3, .rect__hero-4, .rect__hero-5, .rect__hero-6 {
  height: 2.8rem;
}

.rect__hero-1, .rect__hero-2, .rect__hero-3, .rect__hero-4, .rect__hero-5 {
  width: 10.2rem;
}

.rect__hero-1 {
  right: -18rem;
  top: -2rem;
  transition: width 600ms ease 400ms;
}

.rect__hero-2 {
  right: -12rem;
  top: 38rem;
  transition: width 800ms ease 950ms;
}

.rect__hero-3 {
  opacity: .5;
  left: -5rem;
  top: 42.5em;
  z-index: 0;
}

.rect__hero-4 {
  left: -2.5rem;
  top: 43rem;
}

.rect__hero-5 {
  right: 20.5rem;
  top: 70.5rem;
  transition: width 900ms ease 700ms;
}

.rect__hero-6 {
  top: 90.5rem;
  right: -53.7rem;
  width: 29.4rem;
  transition: width 700ms ease 500ms;
}

.rect__what-1, .rect__what-4, .rect__what-5 {
  width: 14.7rem;
}

.rect__what-1, .rect__what-3, .rect__what-4, .rect__what-5 {
  height: 1.8rem;
}

.rect__what-1 {
  right: -4.5rem;
  top: 0;
  transition: width 700ms ease 500ms;
}

.rect__what-2 {
  right: -19rem;
  top: 46.5rem;
  width: 32.6rem;
  height: 3.6rem;
  transition: width 900ms ease 800ms;
}

.rect__what-3 {
  right: 33.5rem;
  top: 65rem;
  width: 5.2rem;
  transition: width 500ms ease 700ms;
}

.rect__what-4 {
  right: -12rem;
  top: 68.5rem;
  transition: width 800ms ease 800ms;
}

.rect__what-5 {
  top: 88.7rem;
  right: 36.5rem;
  transition: width 600ms ease 700ms;
}

.rect--dark {
  background-color: #1f1f1f;
}

.rect--red {
  background-color: #ad0000;
}

.rect--light {
  background-color: #4d4d4d;
}

@media only screen and (max-width: 1024px) {
  .detective__overlay {
    display: none;
  }
}

.hr {
  width: 100%;
  opacity: 0.3;
  height: 0.3rem;
  border-color: #4d4d4d;
}

.hr--abs {
  position: absolute;
}

.hero .bg-images .hr {
  width: 89.5rem;
}

.steps .hr {
  left: 0;
  top: 12.6rem;
  width: 93.3rem;
}

.why .bg-images .hr {
  width: 94.6rem;
}

.why .bg-images .hr:nth-of-type(1) {
  top: 28rem;
  left: 0;
}

.why .bg-images .hr:nth-of-type(2) {
  bottom: 19.5rem;
  right: 0;
}

.what .bg-images .hr,
.sliders .bg-images .hr,
.next-game .bg-images .hr {
  width: 35.9rem;
  background-color: #4d4d4d;
}

.sliders .bg-images .hr {
  left: 0;
  bottom: 4.6rem;
}

.what .bg-images .hr {
  left: -35.9rem;
  top: 16rem;
}

.next-game .bg-images .hr:nth-of-type(1) {
  right: -35.9rem;
  top: -14.3rem;
}

.next-game .bg-images .hr:nth-of-type(2) {
  top: 0;
  right: 14.5rem;
}

.next-game .bg-images .hr:nth-of-type(3) {
  left: -15.5rem;
  top: 23rem;
}

.slider--photo img {
  width: 100%;
}

.slider--photo + .slider__control {
  position: absolute;
  bottom: -3.65rem;
  right: 8rem;
  padding-right: 4.3rem;
  width: 28.2rem;
  height: 7.3rem;
  background-color: #2d2d2d;
  text-align: right;
}

.slider--photo + .slider__control .btn {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.slider--testimonials {
  margin-top: 13.6rem;
  max-width: 105rem;
  margin-left: -10rem;
}

.slider--testimonials + .slider__control {
  margin-top: 6rem;
  text-align: right;
  max-width: 92rem;
}

.slider--testimonials + .slider__control .btn {
  margin-right: 8rem;
}

.testimonial {
  display: flex !important;
  margin-left: 2rem;
  margin-right: 2rem;
}

.testimonial__photo-box {
  margin-right: 2.4rem;
}

.testimonial__photo {
  width: 18.3rem;
  height: 18.3rem;
}

.testimonial__photo img {
  width: 100%;
}

.testimonial__name {
  color: #ad0000;
  margin-bottom: 3.3rem;
}

.testimonial__body {
  padding-left: 8rem;
  line-height: 1.1;
}

@media only screen and (max-width: 1024px) {
  .slider--photo {
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }
  .slider--testimonials {
    margin-left: 0;
  }
  .slider--testimonials + .slider__control {
    margin-top: 4rem;
  }
  .testimonial {
    flex-direction: column;
  }
  .testimonial__photo-box {
    display: flex;
    align-items: flex-end;
    margin-right: 0;
    margin-bottom: 3rem;
  }
  .testimonial__name {
    margin-bottom: 0;
    margin-left: 3rem;
  }
  .testimonial__body {
    padding-left: 0;
  }
}

.container {
  position: relative;
  width: 100%;
}

.container--medium {
  max-width: 120rem;
}

.container--large {
  max-width: 192rem;
}

.row {
  display: flex;
  margin-left: -4rem;
  margin-right: -4rem;
  flex-wrap: wrap;
}

.col-1-of-2 {
  flex: 1 1 calc(50% - 8rem);
  position: relative;
  margin-left: 4rem;
  margin-right: 4rem;
}

.col-1-of-3 {
  flex: 1 1 33.3333%;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .container {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .col-1-of-2 {
    flex: 1 1 100%;
  }
  .col-1-of-3 {
    flex: 1 1 50%;
  }
}

@media only screen and (max-width: 768px) {
  .col-1-of-3 {
    flex: 1 1 100%;
  }
  .container {
    max-width: 100%;
  }
}

/*********** FOOTER ***********/
.footer {
  width: 100%;
  /*
    MindGames Footer
  */
  /*
    GoSocial Footer
  */
}

.footer--mindgames {
  width: 100%;
  height: 12.3rem;
  padding: 1.2rem 0;
  background-color: #000;
}

.footer--mindgames .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.footer--mindgames .footer__left,
.footer--mindgames .footer__right,
.footer--mindgames .footer__center {
  flex: 1 1 33.333333%;
}

.footer--mindgames .footer__left,
.footer--mindgames .footer__right {
  display: flex;
  align-items: center;
}

.footer--mindgames .footer__right {
  justify-content: flex-end;
}

.footer--mindgames .footer__center {
  text-align: center;
}

.footer--mindgames .footer__logo:first-child {
  margin-right: 8.3rem;
}

.footer--mindgames .footer__contact:first-child {
  margin-right: 2rem;
}

.footer--mindgames .footer__contact-icon {
  width: 5.4rem;
}

.footer--gosocial {
  padding-top: 2.58rem;
  padding-bottom: 3.33rem;
  background-color: #ad0000;
}

.footer--gosocial .footer__container {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 1.5rem;
}

.footer--gosocial .footer__logo-box {
  position: relative;
}

.footer--gosocial .footer__logo {
  width: 15rem;
  display: inline-block;
}

.footer--gosocial .footer__logo img {
  width: inherit;
}

.footer--gosocial .footer__powered {
  position: relative;
  margin-right: 6px;
  bottom: .7rem;
}

.footer--gosocial .footer__copyright {
  position: absolute;
  bottom: .7rem;
}

@media only screen and (max-width: 768px) {
  .footer--mindgames .footer__center {
    text-align: center;
    margin: 0 2rem;
  }
  .footer--mindgames .footer__logo:first-child {
    margin-right: 2rem;
  }
  .footer--gosocial {
    padding-bottom: 2.58rem;
  }
  .footer--gosocial .footer__container {
    font-size: 1.7rem;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer--gosocial .footer__copyright {
    position: static;
    margin-bottom: 1rem;
  }
}

.std-logo {
  width: 9.8rem;
  height: 7.9rem;
}

.mind-quiz-logo {
  width: 10.3rem;
  height: 6.8rem;
}

.header {
  padding-top: 10.5rem;
}

.header .hr {
  margin-top: 5rem;
}

@media only screen and (max-width: 768px) {
  .header {
    padding-top: 3.5rem;
  }
}

.link {
  text-decoration: none;
  transition: color 300ms ease;
}

.link--large {
  font-size: 3rem;
}

.link--dark {
  color: #878787;
}

.link--light {
  color: #fff;
}

.link--dynamic {
  color: currentColor;
}

.link:hover, .link:focus, .link:active {
  color: #ad0000;
}

/** 
  Hero
**/
.hero {
  padding-top: 8rem;
}

.hero__description {
  margin: 11.6rem 0;
  line-height: 1.1;
}

.hero .h1,
.hero .btn, .hero__description {
  position: relative;
  z-index: 5;
}

.hero .btn {
  margin-left: 51rem;
}

@media only screen and (max-width: 768px) {
  .hero__description {
    margin: 8.5rem 0;
  }
  .hero .btn {
    margin-left: 0;
  }
}

/** 
  Steps
**/
.steps {
  padding-top: 14.5rem;
}

.steps:not(.bg-images) {
  z-index: 2;
}

.steps__list {
  margin-top: 14rem;
  margin-bottom: 7rem;
}

.steps__item {
  line-height: 1.25;
}

.steps__item:nth-child(-n + 2) {
  margin-bottom: 8rem;
}

.steps__number {
  display: inline-block;
  width: 3.1rem;
  height: 3.1rem;
  text-align: center;
  background-color: #ad0000;
}

.steps .btn {
  margin-left: calc(50% + 4rem);
}

@media only screen and (max-width: 1024px) {
  .steps {
    padding-top: 9rem;
  }
  .steps__list {
    margin-top: 8rem;
    margin-bottom: 7rem;
  }
  .steps__item:nth-child(-n + 3) {
    margin-bottom: 4rem;
  }
  .steps .btn {
    margin-left: 0;
  }
}

.why {
  padding-top: 20rem;
  padding-bottom: 36rem;
  z-index: 3;
}

.why__list {
  margin-top: 15rem;
}

.why__item {
  margin-left: 4rem;
  margin-right: 4rem;
}

.why__item:nth-child(-n + 4) {
  margin-bottom: 6.3rem;
}

.why__item:nth-child(odd) {
  width: calc(44% - 4rem);
}

.why__item:nth-child(even) {
  max-width: calc(56% - 4rem);
}

.why__item:last-child {
  display: flex;
  align-items: flex-end;
}

.why .h3 {
  margin-bottom: 1.5rem;
  color: #878787;
}

.why__text {
  line-height: 2.4rem;
}

@media only screen and (max-width: 768px) {
  .why {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .why__list {
    margin-top: 8rem;
  }
  .why__item:nth-child(n) {
    width: 100%;
    max-width: 100%;
  }
  .why__item:nth-child(n) {
    margin-bottom: 6.3rem;
  }
  .why__text {
    font-size: 2.4rem;
  }
}

.sliders {
  padding-bottom: 26.4rem;
  z-index: 2;
}

.sliders .container {
  display: flex;
}

.sliders .h2 {
  margin-left: 12.5rem;
}

.sliders__left {
  width: 100%;
  max-width: calc(50% - 8rem);
  position: relative;
}

.sliders__right {
  width: 100%;
  max-width: calc(50% + 8rem);
}

@media only screen and (max-width: 1024px) {
  .sliders {
    padding-bottom: 13rem;
  }
  .sliders__left, .sliders__right {
    width: 100%;
    max-width: 100%;
  }
  .sliders .h2 {
    margin-left: 0;
    margin-bottom: 8rem;
  }
  .sliders .slider--photo ~ .slider__control {
    bottom: -3rem;
    right: 5rem;
    width: 16rem;
    height: 6rem;
    padding-right: 0;
  }
  .sliders .slider--photo ~ .slider__control .btn--arrow {
    height: 100%;
    width: 100%;
  }
  .sliders .slider--photo ~ .slider__control .btn--arrow svg {
    height: 4rem;
  }
  .sliders .container {
    flex-direction: column;
  }
}

.what {
  padding-bottom: 20.6rem;
}

.what__list {
  margin-top: 10.8rem;
  margin-bottom: 11.4rem;
}

.what__item {
  position: relative;
}

.what__item:not(:last-child) {
  margin-bottom: 5rem;
}

.what__icon {
  position: absolute;
}

.what__icon.phone-icon {
  left: -5rem;
  width: 1.8rem;
}

.what__icon.people-icon {
  left: -6rem;
  width: 3.5rem;
}

.what__text {
  line-height: 1.25;
}

.what__footprint {
  position: relative;
  font-style: italic;
}

.what__footprint span {
  color: #ad0000;
}

.what__footprint::before {
  content: "*";
  position: absolute;
  top: 0;
  left: -4.9rem;
  font-size: 3rem;
  line-height: 0.95;
  font-style: normal;
  color: #ad0000;
}

@media only screen and (max-width: 1024px) {
  .what {
    padding-bottom: 9rem;
  }
  .what__list {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 6rem;
  }
}

.next-game {
  padding-bottom: 17rem;
}

@media only screen and (max-width: 768px) {
  .next-game {
    padding-bottom: 9rem;
  }
}

.timer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: -10.5rem;
  margin-top: 5rem;
}

.timer__box {
  display: flex;
  align-items: flex-end;
}

.timer__cell {
  margin-right: 7.7rem;
}

.timer__cell::last-child {
  margin-right: 5.2rem;
}

.timer__label {
  line-height: 1;
}

.timer__label--dark {
  color: #717171;
  margin-right: 6.2rem;
}

.timer__number {
  width: 11.8rem;
  text-align: left;
  font-size: 10rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 1024px) {
  .timer {
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .timer {
    flex-direction: column;
    align-items: flex-start;
  }
  .timer__box {
    margin-bottom: 8rem;
  }
  .timer__cell:not(:last-child) {
    margin-right: 3rem;
  }
}
