.header {
  padding-top: 10.5rem;

  .hr {
    margin-top: 5rem;
  }

  .link {
    @extend %font-weight-light;
  }
}

@include breakpoint(phone) {
  .header {
    padding-top: 3.5rem;
  }
}