/*********** BASE ***********/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline-color: $color-red;
}

:root {
  font-size: 43.75%;

  @include breakpoint(desktop-large) {
    font-size: 62.5%;
  }
  @include breakpoint(desktop-small) {
    font-size: 41%;
  }
  @include breakpoint(tablet) {
    font-size: 50%;
  }
  @include breakpoint(phone) {
    // font-size: 1.55vw;
    font-size: 38%;
  }
  @include breakpoint(phone-small) {
    font-size: 1.6vw;
  }  
}

::selection {
  background-color: $color-red;
  color: $color-white;
}

html {
  overflow-x: hidden;
}

body {
  min-height: 100%;
  background-color: $color-black;
  box-sizing: border-box;
  color: $color-white;
  font-family: $font-roboto;
  overflow-x: hidden;
}

// main {
//   overflow-x: hidden;
// }

section {
  position: relative;
}

picture {
  display: block;
}

picture img {
  display: block;
  width: 100%;
}

ul {
  list-style: none;
}

address {
  font-style: normal;
}

// select, input, button {
//   font-family: "";
// }

select, input, button {
  font-family: inherit;
}

option {
  font-family: inherit;
  font-weight: inherit;
}

select {
  // -webkit-appearance: none;
  background-color: transparent;
  position: relative;
}

option {
  &:hover {
    background-color: $color-red;
  }
}
/**********************/
