/*********** FOOTER ***********/

.footer {
  width: 100%;

  
  /*
    MindGames Footer
  */
  &--mindgames {
    width: 100%;
    height: 12.3rem;
    padding: 1.2rem 0;
    background-color: $color-black;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
  }

  &--mindgames &__left,
  &--mindgames &__right,
  &--mindgames &__center {
    flex: 1 1 33.333333%;
  }

  &--mindgames &__left,
  &--mindgames &__right {
    display: flex;
    align-items: center;
  }

  &--mindgames &__right {
    justify-content: flex-end;
  }

  &--mindgames &__center {
    text-align: center;
  }

  &--mindgames &__logo {
    &:first-child {
      margin-right: 8.3rem;
    }
  }

  &--mindgames &__contact {
    &:first-child {
      margin-right: 2rem;
    }
  }

  &--mindgames &__contact-icon {
    width: 5.4rem;
  }

  /*
    GoSocial Footer
  */
  &--gosocial {
    padding-top: 2.58rem;
    padding-bottom: 3.33rem;
    background-color: $color-red;
  }

  &--gosocial &__container {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    color: #fff;
    font-size: 1.5rem;
  }

  &--gosocial &__logo-box {
    position: relative;
  }

  &--gosocial &__logo {
    width: 15rem;
    display: inline-block;

    img {
      width: inherit;
    }
  }

  &--gosocial &__powered {
    position: relative;
    margin-right: 6px;
    bottom: .7rem;
  }

  &--gosocial &__copyright {
    position: absolute;
    bottom: .7rem;
  }

  @include breakpoint(phone) {
    &--mindgames &__center {
      text-align: center;
      margin: 0 2rem;
    }
  
    &--mindgames &__logo {
      &:first-child {
        margin-right: 2rem;
      }
    }

    &--gosocial {
      padding-bottom: 2.58rem;
    }

    &--gosocial &__container {
      font-size: 1.7rem;
      padding: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &--gosocial &__copyright {
      position: static;
      margin-bottom: 1rem;
    }
  }

}


.std-logo {
  width: 9.8rem;
  height: 7.9rem;
}

.mind-quiz-logo {
  width: 10.3rem;
  height: 6.8rem;
}
